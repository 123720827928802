import { useCurrentBreakpoint } from "@client/utils";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { gsap } from "gsap/dist/gsap";
import React from "react";

import { HeroBlock } from "@reactivated";

import { RichTextBlock } from "../RichText";
import { ScrollIndicator } from "../ScrollIndicator";
import AnimatedHeroLogo from "./AnimatedHeroLogo";

import animationStyles from "./animated-hero-logo.module.scss";
import homepageStyles from "./homepage.module.scss";

export default function HomePageHeroBlock(props: HeroBlock) {
    const ref = React.useRef<HTMLSpanElement>(null);
    const wrapperRef = React.useRef<HTMLDivElement>(null);
    const viewport = useCurrentBreakpoint();

    useGSAP(() => {
        gsap.to(ref.current, {
            alpha: 0,
            scrollTrigger: {
                scrub: 0.3,
                start: 0,
                end: "bottom bottom-=100",
            },
        });

        ScrollTrigger.create({
            trigger: wrapperRef.current,
            start: "bottom top",
            onEnter: () => {
                if (viewport.belowMobile) return;
                const animation = document.querySelector(
                    `.${animationStyles.container}`,
                ) as HTMLDivElement;
                animation.style.position = "relative";
            },
            onLeaveBack: () => {
                if (viewport.belowMobile) return;
                const animation = document.querySelector(
                    `.${animationStyles.container}`,
                ) as HTMLDivElement;
                animation.style.position = "fixed";
            },
        });
    });

    return (
        <div ref={wrapperRef} className={homepageStyles.hero}>
            <AnimatedHeroLogo />
            <RichTextBlock
                className={homepageStyles.title}
                largeHeadings={true}
                value={props.subtitle ?? ""}
            />
            <ScrollIndicator />
        </div>
    );
}
