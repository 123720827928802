import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { gsap } from "gsap/dist/gsap";
import React from "react";
import { VisuallyHidden } from "react-aria";

import { FooterNavigationBodyBlock } from "@reactivated";

import { StreamField } from "@thelabnyc/thelabui/src/components/StreamField";

import { useCurrentBreakpoint } from "../../utils";
import { ButtonTertiary, Clickable } from "../Clickables";
import { LabLogo } from "../LabLogo";
import { Svg } from "../Svg";

import styles from "./index.module.scss";

interface IProps {
    value: FooterNavigationBodyBlock;
    fixedLogoFadeInRef?: React.RefObject<HTMLElement>;
    logoFillOverride?: string;
}

export const FooterNavigation = (props: IProps) => {
    const viewport = useCurrentBreakpoint();
    const wrapperRef = React.useRef<HTMLDivElement>(null);
    const logoRef = React.useRef<SVGSVGElement>(null);
    /**
     * Use changing window width to trigger some changes; if we set it up this
     * way we can exclude changes to window height which are less important
     * and can cause problems
     */
    const widthRef = React.useRef(0);

    useGSAP(
        () => {
            const circle = logoRef.current!.querySelector(".logo-circle")!;
            const fadeIn = props.fixedLogoFadeInRef?.current;

            if (!fadeIn) {
                gsap.set(circle, {
                    fill: "white",
                    opacity: 1,
                });

                return;
            }

            const logoPosition = ScrollTrigger.create({
                trigger: wrapperRef.current,
                start: "top top",
                fastScrollEnd: true,
                onEnter() {
                    const logo = logoRef.current!;
                    logo.style.position = "static";
                },
                onLeaveBack() {
                    const logo = logoRef.current!;
                    logo.style.position = "fixed";
                },
            });

            function onResize() {
                if (widthRef.current === document.body.scrollWidth) return;
                widthRef.current = document.body.scrollWidth;
                const logo = logoRef.current!;
                const wrapper = logo.parentElement!;
                const max = 800;
                const height = Math.min(
                    wrapper.getBoundingClientRect().height,
                    max,
                );
                const width = Math.min(
                    wrapper.getBoundingClientRect().width,
                    max,
                );
                const topOffset =
                    wrapper.offsetTop - wrapperRef.current!.offsetTop;
                const top =
                    height === max
                        ? topOffset +
                          (wrapper.getBoundingClientRect().height - height) / 2
                        : topOffset;
                logo.style.height = `${height}px`;
                logo.style.width = `${width}px`;
                logo.style.top = `${top}px`;
                logo.style.left = `${
                    wrapper.offsetLeft +
                    wrapper.getBoundingClientRect().width / 2 -
                    logo.getBoundingClientRect().width / 2
                }px`;
                logo.style.position = logoPosition.isActive
                    ? "static"
                    : "fixed";
            }
            onResize();
            window.addEventListener("resize", onResize);

            const logoSpin = gsap
                .timeline({
                    scrollTrigger: {
                        trigger: fadeIn,
                        start: () => {
                            const logoOffsetTop =
                                logoRef.current?.style.top || "0px";
                            /**
                             * The logo usually has some distance from the top of the page
                             * which gives the appearance of it starting its spin animation
                             * too late. This adjusts for that, unless the fadeIn ref is
                             * close to the top of the page.
                             */
                            const startOffset =
                                fadeIn.offsetTop < parseInt(logoOffsetTop)
                                    ? "0"
                                    : logoOffsetTop;
                            return `top-=${startOffset} top`;
                        },
                        endTrigger: wrapperRef.current,
                        fastScrollEnd: true,
                        scrub: 0.3,
                        end: "top top",
                    },
                })
                .to(circle, {
                    rotate: () => {
                        /** Move this up or down to make the logo spin slower or faster */
                        const speedControl = 585;
                        /**
                         * The logo looks straight every 45 degrees so we want to make sure that
                         * the rotation value is a multiple of it
                         */
                        const rotationMultiple = 45;
                        const rotationDistance =
                            document.body.scrollHeight - fadeIn.offsetTop;
                        return (
                            Math.round(rotationDistance / speedControl) *
                            rotationMultiple
                        );
                    },
                    svgOrigin: "0 0",
                });

            const logoInitialFadeIn = gsap.fromTo(
                circle,
                {
                    alpha: 0,
                },
                {
                    scrollTrigger: {
                        trigger: fadeIn,
                        scrub: true,
                    },
                    alpha: 0.08,
                },
            );

            const logoToWhiteInFooter = gsap
                .timeline({
                    scrollTrigger: {
                        trigger: wrapperRef.current,
                        start: () =>
                            viewport.aboveTablet ? "top 30%" : "top 25%",
                        end: "top top",
                        immediateRender: false,
                        fastScrollEnd: true,
                        scrub: 0.3,
                    },
                })
                .to(circle, {
                    opacity: 1,
                });

            return () => {
                logoInitialFadeIn.kill();
                logoSpin.kill();
                logoPosition.kill();
                logoToWhiteInFooter.kill();
                window.removeEventListener("resize", onResize);
            };
        },
        { dependencies: [viewport.aboveTablet], revertOnUpdate: true },
    );

    return (
        <footer ref={wrapperRef} className={styles.root}>
            <section className={styles.container}>
                <div className={styles.fixedLogo}>
                    <div>
                        <svg viewBox="-100 -100 200 200" ref={logoRef}>
                            <LabLogo
                                fill={props.logoFillOverride}
                                className="logo-circle"
                                opacity={0}
                            />
                            <g>
                                {["teal", "red", "blue"].map((color, i) => {
                                    const { PI, cos, sin } = Math;
                                    const a = (i * (2 * PI)) / 3 + PI / 6;
                                    const h = 50;
                                    return (
                                        <circle
                                            key={i}
                                            cx={cos(a) * h}
                                            cy={sin(a) * h}
                                            r={55}
                                            className={`colored-circle circle-${color}`}
                                            opacity={0}
                                            style={{
                                                mixBlendMode:
                                                    color === "blue"
                                                        ? "darken"
                                                        : "multiply",
                                            }}
                                        />
                                    );
                                })}
                            </g>
                        </svg>
                    </div>
                </div>
                <div className={styles.mainContent}>
                    <h2 className={styles.header}>
                        We are <Svg name="logo-word" />
                    </h2>
                    <StreamField
                        components={{
                            location: ({ value }) => (
                                <li>
                                    <h3>{value.title}</h3>
                                    <p className={styles.locationCopy}>
                                        {value.address1}
                                        {viewport.aboveMobile ? (
                                            <>
                                                <br />
                                            </>
                                        ) : (
                                            " "
                                        )}
                                        {value.address2}
                                    </p>
                                    <p className={styles.locationCopy}>
                                        {value.phone}
                                    </p>
                                </li>
                            ),
                        }}
                        value={props.value}
                        tagName="ul"
                        attrs={{
                            className: styles.locationList,
                        }}
                    />
                </div>
                <section className={styles.footer}>
                    <p>
                        ©2024 thelab. Part of{" "}
                        <Clickable
                            href="https://www.wellcomww.com/"
                            target="_blank"
                        >
                            <Svg name="wellcom" />
                            <VisuallyHidden elementType="span">
                                Wellcom
                            </VisuallyHidden>
                        </Clickable>{" "}
                        worldwide
                    </p>
                    <ul className={styles.socialMediaList}>
                        <li>
                            <ButtonTertiary
                                href="https://www.linkedin.com/company/thelab"
                                target="_blank"
                                noConditionalIcon={true}
                            >
                                Linkedin
                            </ButtonTertiary>
                        </li>
                        <li className={styles.privacy}>
                            <ButtonTertiary href="/privacy-policy">
                                Privacy
                            </ButtonTertiary>
                        </li>
                        <li>
                            <ButtonTertiary href="/modern-slavery-statement">
                                Modern Slavery Statement
                            </ButtonTertiary>
                        </li>
                        <li>
                            <ButtonTertiary href="/whistleblower-policy">
                                Whistleblower Protection
                            </ButtonTertiary>
                        </li>
                    </ul>
                </section>
            </section>
        </footer>
    );
};
