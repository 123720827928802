import colors from "@client/styles/color-variables.module.scss";
import { useGSAP } from "@gsap/react";
import { gsap } from "gsap/dist/gsap";
import React, { useEffect, useState } from "react";

import { ParagraphSlidesBlock } from "@reactivated";

import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { useCurrentBreakpoint } from "../../utils";
import { ButtonPrimary } from "../Clickables";
import { LabLogo } from "../LabLogo";
import { RichTextBlock } from "../RichText";

import mediaBlockStyles from "../ExpandMediaBlock/index.module.scss";
import fullWidthMediaStyles from "../FullWidthMedia/index.module.scss";
import styles from "./homepage.module.scss";

export default function ParagraphSlides(props: ParagraphSlidesBlock) {
    const wrapperRef = React.useRef<HTMLDivElement>(null);
    const sentencesRef = React.useRef<(HTMLSpanElement | null)[]>([]);

    const [activeSlide, setactiveSlide] = useState(0);
    const [initialSlideOffset, setInitialSlideOffset] = useState(0);

    const viewport = useCurrentBreakpoint();

    useGSAP(() => {
        const init = gsap.to(".platform, .fancy-logo", {
            alpha: 1,
            scrollTrigger: {
                trigger: `.${mediaBlockStyles.block}, .${fullWidthMediaStyles.root}`,
                start: "top top",
                fastScrollEnd: true,
                toggleActions: "play pause reverse reset",
            },
        });

        const tl = gsap.timeline({
            defaults: { svgOrigin: "0 0", delay: 1, duration: 0.5 },
            scrollTrigger: {
                trigger: wrapperRef.current,
                start: "top top",
                end: "+=200% top",
                scrub: true,
                pin: true,
                pinSpacing: true,
                fastScrollEnd: true,
                onUpdate: (self) => {
                    if (props.slides) {
                        setactiveSlide(
                            Math.floor(self.progress * props.slides.length),
                        );
                    }
                },
            },
        });
        tl.to(".platform", { alpha: 0, delay: 1.5 }, "marketing")
            .to(".marketing", { alpha: 1, delay: 1.5 }, "marketing")
            .to(".fancy-logo", { fill: colors.blue, delay: 1.5 }, "marketing")
            .to(
                ".fancy-logo",
                { morphSVG: "#logo-marketing", delay: 1.5 },
                "marketing",
            )
            .to(".marketing", { alpha: 0 }, "content")
            .to(".content", { alpha: 1 }, "content")
            .to(".fancy-logo", { fill: colors.red }, "content")
            .to(".fancy-logo", { morphSVG: "#logo-content" }, "content")
            .to(".fancy-logo, .content", { alpha: 0 }, "out");

        return () => {
            tl.kill();
            init.kill();
        };
    });

    useEffect(() => {
        if (sentencesRef.current[0]) {
            setInitialSlideOffset(sentencesRef.current[0]?.offsetHeight / 2);
        }
    }, []);

    const getPositionStart = () => {
        if (viewport.aboveTablet) {
            return 50;
        } else {
            return 75;
        }
    };

    const getPositionTop = () => {
        let additionalY = 0;
        sentencesRef.current.forEach((sentenceRef, i, array) => {
            if (
                sentenceRef &&
                (i === activeSlide ||
                    (activeSlide > i && i === array.length - 1))
            ) {
                additionalY +=
                    sentenceRef.offsetTop + sentenceRef.offsetHeight / 2;
            }
        });

        // Necessary to add initial offset (since initial refs wont trigger a re-render)
        if (additionalY === 0) {
            additionalY = initialSlideOffset;
        }
        return additionalY;
    };

    return (
        <>
            <div
                ref={wrapperRef}
                className={styles.paragraphSlides}
                style={{
                    "--slide-count": props.slides?.length ?? 0,
                }}
            >
                {viewport.belowTablet && (
                    <div className={styles.mobileParagraphGradient}></div>
                )}
                <div
                    className={styles.paragraphSlidesInnerWrapper}
                    style={{
                        top: `calc(${getPositionStart()}% - ${getPositionTop()}px`,
                    }}
                >
                    <div className={styles.paragraphSlideBody}>
                        <p data-label="rich text">
                            {props.slides?.map((slide, i, array) => {
                                if (slide.value.text) {
                                    return (
                                        <span
                                            ref={(ref) =>
                                                (sentencesRef.current[i] = ref)
                                            }
                                            key={i}
                                        >
                                            <RichTextBlock
                                                className={
                                                    (activeSlide > i &&
                                                        i + 1 ===
                                                            array.length) ||
                                                    activeSlide === i
                                                        ? styles.active
                                                        : ""
                                                }
                                                tagName="span"
                                                value={slide.value.text}
                                            />{" "}
                                        </span>
                                    );
                                } else {
                                    return <span key={i}></span>;
                                }
                            })}
                        </p>
                        {props.cta && (
                            <ButtonPrimary
                                icon="arrow"
                                href={props.cta.url ?? undefined}
                            >
                                {props.cta.display_name}
                            </ButtonPrimary>
                        )}
                    </div>
                </div>
            </div>
            <div
                className={concatClassNames([
                    styles.fixedLogo,
                    styles.services,
                ])}
            >
                <svg viewBox="-100 -100 200 200">
                    <path
                        className="platform"
                        d="M-98 0a1 1 0 00124 0 1 1 0 00-124 0ZM16.5-33.5h81M16.5 33.5h81M-10 57l24 41M10 40.5h-50a1 1 0 010-81h50"
                        stroke="black"
                        fill="none"
                        strokeWidth={2}
                        strokeLinecap="round"
                        opacity={0}
                    />
                    <path
                        className="marketing"
                        d="M-32.5 89v-25c5-40 60-40 65 0v25M-70 70v-25c2-35 51-43 62.5-10.5M70 70v-25c-2-35-51-43-62.5-10.5M-32.5 89Z"
                        stroke="black"
                        fill="none"
                        strokeWidth={2}
                        strokeLinecap="round"
                        opacity={0}
                    />
                    <path
                        className="content"
                        d="M-87.5 0c55 75 120 75 175 0-55-75-120-75-175 0Z"
                        stroke="black"
                        fill="none"
                        strokeWidth={2}
                        strokeLinejoin="round"
                        opacity={0}
                    />
                    <LabLogo
                        className="fancy-logo logo-platform"
                        cx={-36}
                        r={28}
                        fill="var(--teal)"
                        opacity={0}
                    />
                    <defs>
                        <LabLogo
                            id="logo-marketing"
                            cy={-35}
                            r={55}
                            fill="var(--blue)"
                        />
                        <LabLogo id="logo-content" r={35} fill="var(--red)" />
                    </defs>
                </svg>
            </div>
        </>
    );
}
