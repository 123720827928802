import React from "react";
import { VisuallyHidden } from "react-aria";
import {
    Tab,
    TabList,
    TabListStateContext,
    TabPanel,
    Tabs,
} from "react-aria-components";

import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { Clickable } from "../Clickables";
import { Svg } from "../Svg";

import styles from "./Carousel.module.scss";

export function CarouselNavigationButtons({
    style = "outline",
    size = "large",
    className,
}: {
    style?: "outline" | "filled";
    size?: "large" | "small";
    className?: string;
}) {
    const state = React.useContext(TabListStateContext);

    const prevKey = state?.collection.getKeyBefore(state.selectedKey);
    const nextKey = state?.collection.getKeyAfter(state.selectedKey);
    const onPrev =
        prevKey != null
            ? () => state.setSelectedKey(prevKey)
            : () => state.setSelectedKey(state.collection?.getLastKey());
    const onNext =
        nextKey != null
            ? () => state.setSelectedKey(nextKey)
            : () => state.setSelectedKey(state.collection?.getFirstKey());

    return (
        <div
            className={concatClassNames([
                className,
                styles.controls,
                style === "outline" ? styles.outline : styles.filled,
                size === "large" ? styles.large : styles.small,
            ])}
        >
            <Clickable
                aria-label={prevKey ? "Previous tab" : "Go to last tab"}
                onPress={onPrev}
            >
                <Svg name="caret-left" />
            </Clickable>
            <Clickable
                aria-label={nextKey ? "Next tab" : "Go to first tab"}
                onPress={onNext}
            >
                <Svg name="caret-right" />
            </Clickable>
        </div>
    );
}

interface CarouselProps {
    /**
     * Should not contain the word "carousel"
     */
    label: string;
    isDisabled?: boolean;
    /**
     * Slide number selected by default
     */
    defaultSelectedKey?: number;
    selectedKey?: number;
    onSelectionChange?: (selectedKey: number) => void;
    className?: string;
    style?: React.CSSProperties;
    controls: React.ReactNode;
}

export default function Carousel({
    children,
    label,
    className,
    onSelectionChange,
    controls = <CarouselNavigationButtons />,
    ...props
}: React.PropsWithChildren<CarouselProps>) {
    const rootProps = {
        ...props,
        className: concatClassNames([className, styles.root]),
    };
    if (React.Children.count(children) === 1) {
        return (
            <div {...rootProps}>
                <div className="react-aria-TabPanel">{children}</div>
            </div>
        );
    }
    return (
        <Tabs
            {...rootProps}
            // @ts-expect-error: react-aria-components does not know that our keys are all numbers, but the implementation of Carousel guarantees it
            onSelectionChange={onSelectionChange}
        >
            <div
                role="group"
                aria-roledescription="carousel"
                aria-label={label}
            >
                {controls}
                {React.Children.map(children, (child, i) => (
                    <TabPanel shouldForceMount id={String(i)}>
                        {child}
                    </TabPanel>
                ))}
                <TabList aria-label="Choose slide to display">
                    {React.Children.map(children, (_, i) => (
                        <Tab id={String(i)}>
                            <VisuallyHidden>Slide {i + 1}</VisuallyHidden>
                        </Tab>
                    ))}
                </TabList>
            </div>
        </Tabs>
    );
}
