import ScrollTrigger from "gsap/dist/ScrollTrigger";
import React from "react";

import {
    ShowcaseBlock as Value,
    _ShowcaseCard,
    _ShowcaseCardListOrNull,
} from "@reactivated";

import { ImageChooserBlock } from "@thelabnyc/thelabui/src/components/ImageChooserBlock";
import { notEmptyOrBlank } from "@thelabnyc/thelabui/src/utils/functional";
import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import Carousel, { CarouselNavigationButtons } from "../Carousel";
import { VideoWithPlayPauseButton } from "../VideoWithPlayPauseButton";

import styles from "./index.module.scss";

interface Cardy {
    card: _ShowcaseCard;
    hasDots: boolean;
    fullLayout: boolean;
}

const Caption = ({ card, hasDots, fullLayout }: Cardy) => {
    if (!card.caption) return <></>;

    return (
        <div
            className={concatClassNames([
                fullLayout ? styles.fullCaption : styles.caption,
                hasDots ? styles.hasDots : "",
            ])}
        >
            <span>{card.caption}</span>
        </div>
    );
};

const Card = ({ card, hasDots, fullLayout }: Cardy) => {
    if (!card.media || card.media.length === 0) return <></>;
    const media = card.media[0];

    return (
        <>
            <div
                className={concatClassNames([
                    styles.showcaseImage,
                    card.rounded ? styles.showcaseImageRounded : "",
                ])}
            >
                {media.type === "video" && (
                    <VideoWithPlayPauseButton
                        value={media.value}
                        wrapperAttrs={{ className: styles.videoWrapper }}
                        attrs={{
                            onLoad: () => {
                                ScrollTrigger.refresh();
                            },
                        }}
                    />
                )}
                {media.type === "image" && (
                    <ImageChooserBlock
                        attrs={{
                            className: fullLayout ? styles.picture : "",
                        }}
                        imageAttrs={{
                            className: fullLayout ? styles.image : "",
                            onLoad: () => {
                                ScrollTrigger.refresh();
                            },
                        }}
                        value={media.value}
                    />
                )}
            </div>
            <Caption card={card} hasDots={hasDots} fullLayout={fullLayout} />
        </>
    );
};

const getAspectRatio = (images: _ShowcaseCardListOrNull | undefined) => {
    const defaultValue = 16 / 9;
    if (!images) return defaultValue;

    const filtered = images
        .map((media) => media.media)
        .filter(notEmptyOrBlank)
        .filter((media) => media.length > 0);

    if (filtered.length === 0) return defaultValue;

    if (filtered.findIndex((media) => media[0].type === "video") > -1) {
        return 16 / 9;
    } else {
        const image = filtered.find((media) => media[0].type === "image");

        if (image && image[0].type === "image") {
            return image[0].value.width / image[0].value.height;
        }
    }

    return defaultValue;
};

export const ShowcaseFullBlock = (props: Value) => {
    const hasDots = props.images && props.images.length > 1 ? true : false;
    const hasACaption =
        (props.images &&
            props.images.findIndex((image) => !!image.caption) > -1) ||
        false;
    return (
        <div
            className={hasDots || hasACaption ? styles.container : ""}
            style={{ "--aspect-ratio": getAspectRatio(props.images) }}
        >
            <div className={styles.layoutFull}>
                <Carousel
                    label="Images from our work"
                    className={styles.carousel}
                    controls={
                        <div className={styles.controlWrapper}>
                            <CarouselNavigationButtons
                                style="filled"
                                className={styles.controls}
                            />
                        </div>
                    }
                >
                    {props.images?.map((card, i) => (
                        <Card
                            key={i}
                            card={card}
                            hasDots={hasDots}
                            fullLayout={props.layout === "Full"}
                        />
                    ))}
                </Carousel>
            </div>
        </div>
    );
};

export const ShowcaseGridBlock = (props: Value) => (
    <div className={styles.container}>
        <div className={styles.layoutGrid}>
            {props.images?.map((card, i) => (
                <Card
                    key={i}
                    card={card}
                    hasDots={
                        props.images && props.images.length > 1 ? true : false
                    }
                    fullLayout={props.layout === "Full"}
                />
            ))}
        </div>
    </div>
);

export default function ShowcaseBlock(props: Value) {
    return props.layout === "Full" ? (
        <ShowcaseFullBlock {...props} />
    ) : (
        <ShowcaseGridBlock {...props} />
    );
}
