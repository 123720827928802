import { useGSAP } from "@gsap/react";
import { gsap } from "gsap/dist/gsap";
import React from "react";

import { TickerBlock } from "@reactivated";

import { RichTextBlock } from "../RichText";

import styles from "./homepage.module.scss";

export default function Ticker(props: TickerBlock) {
    const el = React.useRef<HTMLDivElement>(null);
    useGSAP(() => {
        const q = gsap.utils.selector(el.current);
        const slideTween = gsap.to(q("span"), {
            translateX: "-66%",
            ease: "none",
            scrollTrigger: {
                trigger: el.current,
                scrub: true,
            },
        });

        return () => slideTween.kill();
    });

    return (
        <div ref={el} className={styles.ticker}>
            <RichTextBlock
                tagName="span"
                aria-hidden="true"
                value={props.text ?? ""}
            />
            &nbsp;
            <RichTextBlock tagName="span" value={props.text ?? ""} />
            &nbsp;
            <RichTextBlock
                tagName="span"
                aria-hidden="true"
                value={props.text ?? ""}
            />
        </div>
    );
}
