import FeaturedCaseStudies from "@client/components/FeaturedCaseStudies";
import { FullWidthMedia } from "@client/components/FullWidthMedia";
import { PageSkeleton } from "@client/components/PageSkeleton";
import ShowcaseBlock from "@client/components/ShowcaseBlock";
import Ticker from "@client/components/homepage/Ticker";
import { MorphSVGPlugin } from "gsap/dist/MorphSVGPlugin";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { gsap } from "gsap/dist/gsap";
import React from "react";

import {
    AnimatedFeaturedRichTextBlock,
    LogoListBlock,
    templates,
} from "@reactivated";

import { StreamField } from "@thelabnyc/thelabui/src/components/StreamField";

import ExpandMediaBlock from "../components/ExpandMediaBlock";
import { FeaturedRichTextBlock } from "../components/RichText";
import { SpacerBlock } from "../components/SpacerBlock";
import { Svg } from "../components/Svg";
import Hero from "../components/homepage/Hero";
import ParagraphSlides from "../components/homepage/ParagraphSlides";

import styles from "../components/homepage/homepage.module.scss";

gsap.registerPlugin(ScrollTrigger, MorphSVGPlugin);

function LogoList(props: LogoListBlock) {
    return (
        <div className={styles.logoList}>
            {props.logos?.map(
                (logo, i) => logo.value && <Svg key={i} name={logo.value} />,
            )}
        </div>
    );
}

function FeaturedRichTextWrapper(props: AnimatedFeaturedRichTextBlock) {
    return (
        <div className={styles.rtf}>
            <FeaturedRichTextBlock {...props} />
        </div>
    );
}

export const Template = (props: templates.HomePageTemplate) => {
    const logoFadeInRef = React.useRef<HTMLDivElement>(null);
    return (
        <React.StrictMode>
            <PageSkeleton {...props.page} fixedLogoFadeInRef={logoFadeInRef}>
                <StreamField
                    tagName={React.Fragment}
                    value={props.page.body.value}
                    components={{
                        hero: (block) => <Hero {...block.value} />,
                        hero_image: (block) => <FullWidthMedia {...block} />,
                        expand_media: (block) => (
                            <ExpandMediaBlock {...block.value} />
                        ),
                        paragraph_slides: (block) => (
                            <>
                                <ParagraphSlides {...block.value} />
                                <div ref={logoFadeInRef} />
                            </>
                        ),
                        ticker: (block) => <Ticker {...block.value} />,
                        blog_showcase: (block) => (
                            <FeaturedCaseStudies {...block.value} />
                        ),
                        logo_list: (block) => <LogoList {...block.value} />,
                        showcase: (block) => <ShowcaseBlock {...block.value} />,
                        rich_text: (block) => (
                            <FeaturedRichTextWrapper {...block.value} />
                        ),
                        spacer: (block) => <SpacerBlock value={block.value} />,
                    }}
                />
            </PageSkeleton>
        </React.StrictMode>
    );
};
