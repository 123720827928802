import React, { Fragment } from "react";

import {
    BlogPostListItemSubBlock,
    DoubleCaseStudyShowcase,
    SingleCaseStudyShowcase,
    FeaturedCaseStudiesBlock as Value,
} from "@reactivated";

import { Clickable } from "@thelabnyc/thelabui/src/components/Clickable";
import { MediaChooserBlock } from "@thelabnyc/thelabui/src/components/MediaChooserBlock";
import { VideoChooserBlock } from "@thelabnyc/thelabui/src/components/VideoChooserBlock";
import {
    classNames,
    concatClassNames,
} from "@thelabnyc/thelabui/src/utils/styles";

import { ButtonPrimary } from "../Clickables";

import styles from "./index.module.scss";

function CaseStudyCard({
    animate = false,
    ...props
}: BlogPostListItemSubBlock & { className?: string; animate?: boolean }) {
    const linkRef = React.useRef<HTMLAnchorElement>(null);

    return (
        <div
            className={concatClassNames([styles.card, props.className])}
            onClick={() => linkRef.current?.click()}
        >
            <div className={styles.content}>
                <div>
                    {props.media[0].type === "video" ? (
                        <VideoChooserBlock
                            iOSFriendlyMutedAutoPlay={true}
                            attrs={{
                                loop: true,
                                controls: false,
                            }}
                            value={props.media[0].value}
                        />
                    ) : (
                        <MediaChooserBlock value={props.media} />
                    )}
                </div>

                <div className={styles.title}>
                    <Clickable ref={linkRef} href={props.url}>
                        <h3>{props.title}</h3>
                    </Clickable>
                    <p>{props.client}</p>
                </div>
                {props.tags.length > 0 && (
                    <ul className={styles.tags}>
                        {props.tags.map((tag) => (
                            <li key={tag.id}>{tag.name}</li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
}

interface ISingleCaseStudyLine {
    type: "Single";
    value: SingleCaseStudyShowcase;
    id: string;
}
interface IDoubleCaseStudyLine {
    type: "Double";
    value: DoubleCaseStudyShowcase;
    id: string;
}

function CaseStudyLine(props: ISingleCaseStudyLine | IDoubleCaseStudyLine) {
    let cards;
    if (props.type === "Single" && props.value.post) {
        cards = <CaseStudyCard animate {...props.value.post} />;
    } else if (props.type === "Double") {
        cards = (
            <>
                {props.value.left && (
                    <CaseStudyCard
                        {...props.value.left}
                        animate
                        className={classNames([
                            [styles.primary, props.value.primary === "left"],
                            [styles.equal, props.value.primary === "equal"],
                            [
                                styles.secondary,
                                props.value.primary != "equal" &&
                                    props.value.primary != "left",
                            ],
                        ])}
                    />
                )}
                {props.value.right && (
                    <CaseStudyCard
                        {...props.value.right}
                        className={classNames([
                            [styles.primary, props.value.primary === "right"],
                            [styles.equal, props.value.primary === "equal"],
                            [
                                styles.secondary,
                                props.value.primary != "equal" &&
                                    props.value.primary != "right",
                            ],
                        ])}
                    />
                )}
            </>
        );
    }

    return <div className={styles[`row${props.type}`]}>{cards}</div>;
}

export default function FeaturedCaseStudies(props: Value) {
    return props.lines?.map((line, i, ary) => {
        return (
            <Fragment key={i}>
                <CaseStudyLine key={i} {...line} />
                <>
                    {i + 1 === ary.length &&
                        props.cta &&
                        props.cta.display_name && (
                            <div className={styles.cta}>
                                <ButtonPrimary
                                    href={props.cta.url ?? undefined}
                                    icon="arrow"
                                >
                                    {props.cta.display_name}
                                </ButtonPrimary>
                            </div>
                        )}
                </>
            </Fragment>
        );
    });
}
