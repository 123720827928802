import colors from "@client/styles/color-variables.module.scss";
import "@client/styles/global.scss";
import themes from "@client/styles/theming.module.css";
import React from "react";
import { Helmet } from "react-helmet-async";

import { Context, constants, reverse } from "@reactivated";

import { notEmpty } from "@thelabnyc/thelabui/src/utils/functional";

import { FooterNavigation } from "../FooterNavigation";
import { HeaderNavigation } from "../HeaderNavigation";

interface Props {
    title: string;
    seo_title?: string;
    search_description?: string;
    robots?: string;
    rootClassName?: string;
    children?: React.ReactNode;
    color_theme?: string;
    fixedLogoFadeInRef?: React.RefObject<HTMLElement>;
}

/**
 * Similar to what thelabui does with root mean square; if it doesn't hold up
 * then feel free to improve it
 */
export const calculateBrightness = (
    r: string | number,
    g: string | number,
    b: string | number,
    a: string | number,
) => {
    r = typeof r === "number" ? r : parseInt(r);
    g = typeof g === "number" ? g : parseInt(g);
    b = typeof b === "number" ? b : parseInt(b);
    a = typeof a === "number" ? a : parseInt(a);

    // Assuming a translucent background is on white (255, 255, 255)
    const opacityAdjustment = (1 - a) * 255;
    const adjusted = {
        r: opacityAdjustment + a * r,
        g: opacityAdjustment + a * g,
        b: opacityAdjustment + a * b,
    };
    const relativeLuminance =
        (0.2126 * adjusted.r + 0.7152 * adjusted.g + 0.0722 * adjusted.b) / 255;
    return Math.sqrt(relativeLuminance) * 255;
};

export const PageSkeleton = (props: Props) => {
    const context = React.useContext(Context);
    const mainRef = React.useRef<HTMLDivElement>(null);

    const [themeForHeader, setThemeForHeader] = React.useState(themes.cream);

    const getBrightness = () => {
        const main = mainRef.current;
        const mainArray = main ? Array.from(main.children) : [];

        /**
         * Anything with offsetTop - scrollY <= 0 is out of view (including
         * things with `display: none`, which we want to ignore); the last one
         * to trigger is the one that we want to determine the brightness value
         */
        const activeElement = mainArray
            .filter(
                (node) =>
                    node instanceof HTMLElement &&
                    node.offsetTop - scrollY <= 0 &&
                    window
                        .getComputedStyle(node)
                        .getPropertyValue("display") !== "none",
            )
            .at(-1);
        if (!activeElement) return;

        let backgroundColor =
            window.getComputedStyle(activeElement).backgroundColor;

        /**
         * If activeElement's backgroundColor is rgba(0, 0, 0, 0) then it's
         * transparent, and we should use the background color of the page instead
         */
        if (backgroundColor === "rgba(0, 0, 0, 0)") {
            backgroundColor = window.getComputedStyle(
                document.querySelector("html")!,
            ).backgroundColor;
        }
        const parseBackgroundColor = backgroundColor
            .replace(/\s/g, "")
            .match(
                /rgba?\((\d+(?:\.\d+)?),(\d+(?:\.\d+)?),(\d+(?:\.\d+)?)(?:,(\d+(?:\.\d+)?))?\)/i,
            );
        if (parseBackgroundColor === null) return;
        const [, r, g, b, a] = parseBackgroundColor;
        const brightness = calculateBrightness(
            r,
            g,
            b,
            typeof a === "undefined" ? 1 : a,
        );

        /**
         * Get brightness values of official theme colors
         */
        const themeBrightness = Object.entries(colors)
            .map((color) => {
                const rgb = color[1].match(/rgb\((\d+)\s+(\d+)\s+(\d+)\)/);
                if (!rgb) return null;
                return [
                    color[0],
                    calculateBrightness(rgb[1], rgb[2], rgb[3], 1),
                ];
            })
            .filter(notEmpty);

        /**
         * If the brightness value matches an official theme color's brightness
         * value, then set the header to use that theme. Otherwise, use black or
         * cream.
         */
        const match = themeBrightness.find((value) => value[1] === brightness);
        if (match && typeof match[0] === "string" && match[0] in themes) {
            setThemeForHeader(themes[match[0] as keyof typeof themes]);
        } else {
            setThemeForHeader(
                brightness < 255 / 2 ? themes.black : themes.cream,
            );
        }
    };

    React.useEffect(() => {
        getBrightness();
        window.addEventListener("scroll", getBrightness);
        return () => {
            window.removeEventListener("scroll", getBrightness);
        };
    }, []);

    return (
        <>
            <Helmet>
                <html
                    className={
                        props.color_theme
                            ? themes[props.color_theme as keyof typeof themes]
                            : themes.cream
                    }
                />
                <meta charSet="utf-8" />
                <title>{props.seo_title || props.title}</title>
                {props.search_description && (
                    <meta
                        name="description"
                        content={props.search_description}
                    />
                )}
                {props.robots && <meta name="robots" content={props.robots} />}
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1"
                />
                <link
                    rel="apple-touch-icon"
                    sizes="180x180"
                    href={context.core_assets.apple_touch_icon}
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="32x32"
                    href={context.core_assets.favicon_png_lg}
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="16x16"
                    href={context.core_assets.favicon_png_sm}
                />
                <link rel="manifest" href={reverse("pwa-site-manifest")} />
                <link
                    rel="mask-icon"
                    href={context.core_assets.mask_icon}
                    color={constants.THEME_COLOR}
                />
                <link
                    rel="shortcut icon"
                    href={context.core_assets.favicon_ico}
                />
                <meta
                    name="msapplication-TileColor"
                    content={constants.THEME_COLOR}
                />
                <meta
                    name="msapplication-config"
                    content={reverse("msapplication-config")}
                />
                <meta name="theme-color" content={constants.THEME_COLOR} />
                {/* GTM */}
                <script
                    type="text/javascript"
                    nonce={context.request.csp_nonce || undefined}
                >{`
                    dataLayer = [];
                    (function(w, d, s, l, i) {
                        w[l] = w[l] || [];
                        w[l].push({
                            'gtm.start':
                            new Date().getTime(),
                            event: 'gtm.js'
                        });
                        var f = d.getElementsByTagName(s)[0],
                            j = d.createElement(s),
                            dl = l != 'dataLayer' ? '&l=' + l : '';
                        j.async = true;
                        j.src =
                        'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
                        f.parentNode.insertBefore(j, f);
                    })(window, document, 'script', 'dataLayer', 'GTM-TG6RJ2HZ');
                `}</script>
            </Helmet>
            <div className={props.rootClassName}>
                {context.header_nav && (
                    <HeaderNavigation
                        value={context.header_nav.body.value}
                        themeOverride={themeForHeader}
                    />
                )}
                <main ref={mainRef}>
                    {props.children}
                    {context.footer_nav && (
                        <FooterNavigation
                            value={context.footer_nav.body.value}
                            fixedLogoFadeInRef={props.fixedLogoFadeInRef}
                            logoFillOverride={
                                props.color_theme === "black" ||
                                props.color_theme === "darkGray" ||
                                props.color_theme === "coolGray"
                                    ? colors.white
                                    : undefined
                            }
                        />
                    )}
                </main>
            </div>
        </>
    );
};
